const ToolTip = ({ children, text }) => {
  return (
    <div className="has-tooltip relative flex items-center">
      {children}
      <div className="tooltip absolute bottom-full w-64 mb-4 rounded-md bg-black px-1 py-1 text-center text-xs text-white shadow-lg group-hover:block">
        {text}
        <div className="absolute left-0 w-0 h-0 -ml border-x-transparent border-t-transparent border-b-black"></div>
      </div>
    </div>
  );
}

export default ToolTip;