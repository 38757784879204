import StatusOverview from './components/StatusOverview';

function App() {
  return (
    <div className="min-h-screen">
      <StatusOverview />
    </div>
  );
}

export default App;