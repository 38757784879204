import StatusCard from './StatusCard';
import { useEffect, useState } from 'react';
import axios from 'axios';

const StatusOverview = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://data-openapi.wantedlab.com/bolt')
      .then(response => {
        setServices(response.data);
      })
      .catch(error => {
        console.error('Error fetching data', error);
      })
      .finally(() => {
        setLoading(false);  // 데이터 로딩 성공/실패 후 로딩 상태 업데이트
      });
  }, []);

  if (loading) {
    return (
      <div className="bg-white py-12 sm:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <svg className="animate-spin h-12 w-12 mx-auto text-gray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            <p className="mt-4 text-lg font-semibold text-gray-900">Loading...</p>
            <p className="mt-2 text-sm text-gray-600">데이터를 가져오고 있습니다. 잠시만 기다려 주세요!</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white py-12 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8 text-center">
          <span className={`px-4 py-2 flex items-center justify-center w-full rounded shadow-md text-lg md:text-xl font-semibold text-gray-900 ${services.every(service => service.status === 'Normal') ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
            <svg className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={services.every(service => service.status === 'Normal') ? "M5 13l4 4L19 7" : "M6 18L18 6M6 6l12 12"} />
            </svg>
            {services.every(service => service.status === 'Normal') ? 'All Systems Operational' : 'Some Systems are Down'}
          </span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {services.map(service => (
            <StatusCard
              key={service.id || service.name}
              serviceName={service.name}
              serviceStatus={service.status}
              serviceDescription={service.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatusOverview;
