import ToolTip from "./ToolTip";

const StatusCard = ({ serviceName, serviceStatus, serviceDescription }) => {
    let statusColor;
    switch (serviceStatus) {
      case 'Normal':
        statusColor = 'bg-green-500';
        break;
      case 'In Progress':
        statusColor = 'bg-yellow-500';
        break;
      default:
        statusColor = 'bg-red-500';
    }

    return (
      <div className="border p-4 rounded-lg shadow-md flex items-center justify-between">
        <div className="flex items-center">
          <span className="text-lg font-medium text-gray-900">{serviceName}</span>
          <ToolTip text={serviceDescription}>
            <span className="ml-2 px-2 py-1 rounded-full text-xs text-gray-600 bg-gray-200 cursor-help">?</span>
          </ToolTip>
        </div>
        <div className={`text-sm font-semibold text-white px-4 py-1 rounded-full ${statusColor}`}>
          {serviceStatus}
        </div>
      </div>
    );
};

export default StatusCard;
